import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faFacebookF,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

import "../css/layout.scss"

import ticketsImage from "../images/tickets.jpg"

const IndexPage = () => {
  return (
    <div id="page-wrapper">

      <header id="menu-bar">
        <h1>The<br />Leathernecks</h1>

        <nav>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#audio">Audio</a></li>
            <li><a href="#video">Video</a></li>
            <li><a href="#gigs">Gigs</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>

        <nav>
          <ul class="social-links">
            <li><a href="https://www.youtube.com/user/theleathernecksmusic" title="The Leathernecks on YouTube"><FontAwesomeIcon icon={faYoutube} /></a></li>
            <li><a href="https://www.facebook.com/theleathernecks" title="The Leathernecks on Facebook"><FontAwesomeIcon icon={faFacebookF} /></a></li>
            <li><a href="https://instagram.com/the_leathernecks" title="The Leathernecks on Instagram"><FontAwesomeIcon icon={faInstagram} /></a></li>
          </ul>
        </nav>
      </header>

      <main>

        <section id="about">
          <div class="section-content">
            <h2>The Best Indie-Rock Covers</h2>
            <p>
              Offering a live show like no other, The Leathernecks are passionate musicians, 
              energetic and ready for a party! Bringing you the very best in floor-filling Indie-Rock, 
              their set list is packed with iconic anthems everyone knows and loves, from Oasis to 
              The Jam, Kings Of Leon, Arctic Monkeys and more! The band has played hundreds of weddings, 
              corporate events, and private functions across the UK and overseas, The Leathernecks Live 
              is an experience you'll want again and again!</p>

              <a href="https://theleathernecks.bigcartel.com/" title="Buy tickets for The Leathernecks gigs"><img src={ticketsImage} alt="The Leathernecks Tickets" /></a>
          </div>
        </section>

        <section id="audio">
          <div class="section-content">
            <h2>Audio</h2>
            <iframe class="soundcloud" title="Leathernecks on SoundCloud" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?visual=false&amp;url=https%3A%2F%2Fapi.soundcloud.com%2Fplaylists%2F272079727&amp;show_artwork=true&amp;color=%23b36a57&amp;show_comments=true&amp;show_playcount=true"></iframe>
          </div>
        </section>

        <section id="video">
          <div class="section-content">
            <h2>Video</h2>
            <iframe class="youtube-video" title="Leathernecks on YouTube" src="https://www.youtube.com/embed/videoseries?list=PLaSn8ZUTeLHS5rjHwon0PFcr2yX3Lj9s6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </section>

        <section id="gigs">
          <div class="section-content"> 
            <h2>Gigs</h2>
            <p>For tickets visit <a href="https://theleathernecks.bigcartel.com/" title="Leathernecks tickets">https://theleathernecks.bigcartel.com/</a></p>
            <iframe class="calendar" title="Leathernecks Gig Calendar" src="https://calendar.google.com/calendar/embed?mode=AGENDA&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FLondon&showTitle=0&showDate=1&showPrint=0&showTabs=0&showCalendars=0&showTz=0&hl=en_GB&src=NG80cW9obmxrZWVhYW51bXExNWpkYWI1cjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23AD1457" frameborder="0" scrolling="no"></iframe>
          </div>
        </section>

        <section id="contact">
          <div class="section-content">
            <h2>Contact The Leathernecks</h2>
            <p class="large-text">For all enquiries and bookings please email<br /><a href="mailto:theleathernecksmusic@gmail.com">theleathernecksmusic@gmail.com</a></p>
            <ul class="list-inline social-links">
              <li><a href="https://www.youtube.com/user/theleathernecksmusic" title="The Leathernecks on YouTube"><FontAwesomeIcon icon={faYoutube} size="2x" /></a></li>
              <li><a href="https://www.facebook.com/theleathernecks" title="The Leathernecks on Facebook"><FontAwesomeIcon icon={faFacebookF} size="2x" /></a></li>
              <li><a href="https://instagram.com/the_leathernecks" title="The Leathernecks on Instagram"><FontAwesomeIcon icon={faInstagram} size="2x" /></a></li>
            </ul>
          </div>
        </section>
        
      </main>

    </div>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <title>The Leathernecks - Indie Rock Covers Band, UK</title>
    <meta name="description" content="The Leathernecks are and Indie Rock Covers band in the UK with sets packed with iconic anthems everyone knows and loves, from Oasis to The Jam, Kings Of Leon, Arctic Monkeys and more!"></meta>
  </>
)